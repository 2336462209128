<template>
    <b-modal id="form-modal">
        <b-form-group label="Dari Kas" class="mb-2">
            <b-form-input :value="currentKas ? currentKas.nama : ''" disabled></b-form-input>
        </b-form-group>
        <b-form-group label="Pilih Tujuan Kas" class="mb-2">
            <v-select v-model="form.ke_kas" :options="kasOptions" :reduce="option => option.value" label="text"></v-select>
        </b-form-group>
        <b-form-group label="Nominal" class="mb-2">
            <b-form-input class="mb-1" v-model="form.nominal" @keyup="form.nominal = formatRupiah(form.nominal)"></b-form-input>
            <i class="text-danger" v-if="currentKas">
                <strong>Maksimal Mutasi: Rp {{ formatRupiah(currentKas.saldo) }}</strong>
            </i>
        </b-form-group>

        <template #modal-footer>
            <b-button variant="primary" :disabled="!isValidForm" @click.prevent="submit">Mutasikan</b-button>
        </template>
    </b-modal>
</template>
<script>
import {BModal, BFormGroup, BFormInput, BButton} from 'bootstrap-vue'
import vSelect from 'vue-select'
export default {
    components: {
        BModal, BFormGroup, BFormInput, BButton, vSelect
    },
    data: () => ({
        form: {
            ke_kas: null,
            nominal: 0
        }
    }),
    computed: {
        isValidForm() {
            const nominal = this.unFormatRupiah(this.form.nominal)
            if(!this.form.ke_kas || nominal < 1) {
                return false
            }

            return true
        },
        kasOptions() {
            return this.$store.state.kas.kasOptions
        },
        currentKas() {
            return this.$store.state.kas.currentKas
        }
    },
    methods: {
        submit() {
            if(!this.isValidForm) {
                this.displayError({
                    message: 'Harap lengkapi form mutasi kas!'
                })
                return false
            }

            const payload = this.form
            payload.nominal = this.unFormatRupiah(payload.nominal)

            if(payload.nominal > this.currentKas.saldo) {
                this.displayError({
                    message: `Nominal tidak boleh melebihi saldo kas ${this.currentKas.nama}!`
                })
                return false
            }

            this.$emit('submit', payload)
        }
    }
}
</script>