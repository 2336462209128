var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('h2', {
    staticClass: "text-primary"
  }, [_vm._v("Kas : " + _vm._s(_vm.kas.nama))])]), _c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('h3', {
    staticClass: "text-info"
  }, [_vm._v("Saldo : Rp. " + _vm._s(_vm.kas.saldo ? _vm.formatRupiah(_vm.kas.saldo) : "-"))])])], 1)], 1)], 1), _c('b-col', [_c('b-card', [_c('b-row', [_c('b-col', {
    staticClass: "my-1"
  }, [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.openFormModal($event);
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PlusIcon"
    }
  }), _vm._v(" Mutasi ")], 1), _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.openFormModalJurnal($event);
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PlusIcon"
    }
  }), _vm._v(" Kas Debit ")], 1), _c('form-modal', {
    on: {
      "submit": _vm.submit
    }
  }), _c('form-jurnal', {
    on: {
      "submitJurnal": _vm.submitJurnal
    }
  }), _c('b-modal', {
    attrs: {
      "id": "modal-",
      "ok-title": "Tutup",
      "ok-variant": "secondary",
      "ok-only": "",
      "centered": "",
      "title": "Form "
    },
    model: {
      value: _vm.showModal,
      callback: function ($$v) {
        _vm.showModal = $$v;
      },
      expression: "showModal"
    }
  }, [_c('validation-observer', {
    ref: "formkas_alur"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "ArchiveIcon",
      "size": "19"
    }
  }), _c('h6', {
    staticClass: "ml-50 mb-2"
  }, [_vm._v("Data Kas Alur")])], 1), _c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [this.userData.level.id == 4 ? _c('b-form-group', [_c('validation-provider', {
    attrs: {
      "name": "id_karyawan",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var errors = _ref.errors;
        return [_c('b-form-select', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "options": _vm.id_karyawan,
            "id": "v-id_karyawan",
            "name": "id_karyawan",
            "disabled": "",
            "hidden": ""
          },
          model: {
            value: _vm.form.id_karyawan,
            callback: function ($$v) {
              _vm.$set(_vm.form, "id_karyawan", $$v);
            },
            expression: "form.id_karyawan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 4100519117)
  })], 1) : _vm._e(), this.userData.level.id == 7 ? _c('b-form-group', {
    attrs: {
      "label": "Pilih Karyawan",
      "label-for": "v-id_karyawan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "id_karyawan",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-select', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "options": _vm.id_karyawan,
            "id": "v-id_karyawan",
            "name": "id_karyawan",
            "disabled": "",
            "hidden": ""
          },
          model: {
            value: _vm.form.id_karyawan,
            callback: function ($$v) {
              _vm.$set(_vm.form, "id_karyawan", $$v);
            },
            expression: "form.id_karyawan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 4100519117)
  })], 1) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Pilih Jenis",
      "label-for": "v-jenis"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "jenis",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        var errors = _ref3.errors;
        return [_c('b-form-radio', {
          staticClass: "custom-control-info",
          attrs: {
            "name": "some-radio9",
            "value": "1"
          },
          model: {
            value: _vm.form.jenis,
            callback: function ($$v) {
              _vm.$set(_vm.form, "jenis", $$v);
            },
            expression: "form.jenis"
          }
        }, [_vm._v(" Masuk ")]), _c('b-form-radio', {
          staticClass: "custom-control-danger",
          attrs: {
            "name": "some-radio9",
            "value": "2"
          },
          model: {
            value: _vm.form.jenis,
            callback: function ($$v) {
              _vm.$set(_vm.form, "jenis", $$v);
            },
            expression: "form.jenis"
          }
        }, [_vm._v(" Keluar ")]), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tanggal",
      "label-for": "v-tanggal"
    }
  }, [_c('validation-provider', {
    attrs: {
      "rules": "required",
      "name": "tanggal"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        var errors = _ref4.errors;
        return [_c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "config": {
              enableTime: true,
              dateFormat: 'Y-m-d H:i'
            }
          },
          model: {
            value: _vm.form.tanggal,
            callback: function ($$v) {
              _vm.$set(_vm.form, "tanggal", $$v);
            },
            expression: "form.tanggal"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nominal Kas Alur",
      "label-for": "v-nominal"
    }
  }, [_c('validation-provider', {
    attrs: {
      "rules": "required",
      "name": "kas_alur"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref5) {
        var errors = _ref5.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-nominal",
            "placeholder": "Ex: 250.000"
          },
          on: {
            "keyup": _vm.doFormatRupiah
          },
          model: {
            value: _vm.form.nominal,
            callback: function ($$v) {
              _vm.$set(_vm.form, "nominal", $$v);
            },
            expression: "form.nominal"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Keterangan",
      "label-for": "v-keterangan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "keterangan"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref6) {
        var errors = _ref6.errors;
        return [_c('b-form-textarea', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-keterangan",
            "placeholder": "Isi Keterangan"
          },
          model: {
            value: _vm.form.keterangan,
            callback: function ($$v) {
              _vm.$set(_vm.form, "keterangan", $$v);
            },
            expression: "form.keterangan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mr-1",
    attrs: {
      "type": "submit",
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v(" Tambah ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "type": "reset",
      "variant": "outline-secondary"
    }
  }, [_vm._v(" Reset ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function ($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "4",
      "sm": "8"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Sort'),
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "sortBySelect"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-select', {
    staticClass: "w-75",
    attrs: {
      "id": "sortBySelect",
      "options": _vm.sortOptions
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("-- none --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sortBy,
      callback: function ($$v) {
        _vm.sortBy = $$v;
      },
      expression: "sortBy"
    }
  }), _c('b-form-select', {
    staticClass: "w-25",
    attrs: {
      "size": "sm",
      "disabled": !_vm.sortBy
    },
    model: {
      value: _vm.sortDesc,
      callback: function ($$v) {
        _vm.sortDesc = $$v;
      },
      expression: "sortDesc"
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("Asc")]), _c('option', {
    domProps: {
      "value": true
    }
  }, [_vm._v("Desc")])])], 1)], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Filter'),
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search"
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function ($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "items": _vm.items,
      "fields": _vm.fields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    on: {
      "update:sortBy": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function ($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function ($event) {
        _vm.sortDesc = $event;
      },
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(tanggal)",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(_vm.humanDate(item.tanggal)) + " ")];
      }
    }, {
      key: "cell(debit)",
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_c('strong', [_vm._v("Rp " + _vm._s(item.debit > 0 ? _vm.formatRupiah(item.debit) : item.debit))])];
      }
    }, {
      key: "cell(kredit)",
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_c('strong', [_vm._v("Rp " + _vm._s(item.kredit > 0 ? _vm.formatRupiah(item.kredit) : item.kredit))])];
      }
    }, {
      key: "cell(avatar)",
      fn: function (data) {
        return [_c('b-avatar', {
          attrs: {
            "src": data.value
          }
        })];
      }
    }, {
      key: "cell(jenis)",
      fn: function (data) {
        return [_c('b-badge', {
          attrs: {
            "variant": _vm.jns[1][data.value]
          }
        }, [_vm._v(" " + _vm._s(_vm.jns[0][data.value]) + " ")])];
      }
    }, {
      key: "cell(actions)",
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_c('div', {
          staticClass: "flex align-items-center justify-center"
        }, [_vm.allowDelete() && item.modul == 'kas' ? _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": function ($event) {
              return _vm.removeJurnal(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrashIcon"
          }
        })], 1) : _vm._e()], 1)];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "id": _vm.detailmodal.id,
      "title": _vm.detailmodal.title,
      "ok-only": ""
    }
  }, [_c('pre', [_vm._v(_vm._s(_vm.detailmodal.content))])]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }